import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './Navigation.module.scss'
import { FiMenu } from 'react-icons/fi'
import { HiChevronUp } from 'react-icons/hi'
import { Button } from 'react-bootstrap'

const Navigation = (props) => {
    const [menuActive, setMenuActive] = useState(false);
    const path = useLocation().pathname;

    useEffect(() => {
        setMenuActive((ma) => ma ? false : ma)
    }, [path])

    return (
        <nav className={styles.navWrapper} aria-label="Hauptmenü">
            <Button variant='light' className={(menuActive) ? styles.menuBtn + " " + styles.active : styles.menuBtn} title='Menü ausklappen' onClick={() => setMenuActive(!menuActive)}>
                <FiMenu aria-hidden="true" color='#004994' size={32} />
            </Button>
            <ul className={(menuActive) ? styles.navigationOverlay + " " + styles.active : styles.navigationOverlay}>
                <li><NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to="/">Home</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to="/schlafometer">
                    {(props.smStarted && !props.smEnded) ? "Schlafometer fortsetzen" : (props.smEnded) ? "Schlafometer Ergebnisse" : "Schlafometer starten"}
                </NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to="/schlafometer-infos">Was ist das Schlafometer</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to="/schlafen">Besser schlafen</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to="/unternehmen">Für Unternehmen</NavLink></li>
                {/* <li><NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to="/tester">Test-Einstellungen</NavLink></li> */}
                <li><NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to="/speichern-laden">Zwischenstand speichern/laden</NavLink></li>
                <li><NavLink to="/impressum" className={styles.footerLink}>Impressum</NavLink></li>
                <li><NavLink to="/datenschutz" className={styles.footerLink}>Datenschutz</NavLink></li>
                <li><NavLink to="/barrierefreiheit" className={styles.footerLink}>Barrierefreiheit</NavLink></li>
                <li><NavLink to="/kontakt" className={styles.footerLink}>Kontakt</NavLink></li>
                {/* {(props.smStarted) && <NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} to="/speichern-laden">Schlafometer-Fortschritt speichern</NavLink>} */}
                <li>
                    <Button className={styles.closeMenuBtn} onClick={() => setMenuActive(false)} variant='light' title='Menü schließen'>
                        <HiChevronUp aria-hidden="true" color='#004994' size={32} />
                    </Button>
                </li>
            </ul>
        </nav>
    )
}

export default Navigation